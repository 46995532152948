import { useState, useEffect } from "react";
import { Grid, Box, Button, TextField, Tooltip, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import toolsDataModelApi from "../../../tools/toolsDataModelApi";
import ToolsNotifications from "../../../tools/ToolsNotifications";
import usePreview from "./usePreview";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useDispatch } from 'react-redux';
import { setPicklist } from "../../../features/picklistSlice";
import { setOpenOverlay } from "../../../features/openOverlaySlice";

export default function usePicklist() {
    const dispatch = useDispatch();
    const picklist = useSelector((state) => state.picklist.value);
    const openOverlay = useSelector((state) => state.openOverlay.value);
    const { showRaw } = usePreview();
    const { success, error } = ToolsNotifications();
    const { getDataModelPicklist, postPicklistRules, getPicklistRulesMapped} = toolsDataModelApi();
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const gpc = useSelector((state) => state.gpc.value);

    const showUpdateMessage = () => {
        let msg = "Conversions updated successfully";
        success(msg);
    }

    const showUpdateMessageErr = () => {
        let msg = "Conversions updated error";
        error(msg);
    }

    const showUpdateMessageServerErr = () => {
        let msg = "Server error";
        error(msg);
    }

    const savePicklistSlice = (value) => {
        dispatch(setPicklist(value));
    }
    const savePicklist = (value) => {
        dispatch(setPicklist({picklist: value, rowPicklist: picklist.rowPicklist, picklistConversions:  picklist.picklistConversions }));
    }
    const saveRowPicklist = (value) => {
        dispatch(setPicklist({picklist: picklist.picklist , rowPicklist: value, picklistConversions:  picklist.picklistConversions }));
    }

    const savePicklistConversions = (value) => {
        dispatch(setPicklist({picklist: picklist.picklist , rowPicklist: picklist.rowPicklist, picklistConversions:  value }));
    }

    useEffect(() => {
        console.log("AQIO")
        console.log(picklist)
        console.log(selectedDataModel)
        if(selectedDataModel !== undefined && selectedDataModel.type !== undefined && selectedDataModel.version !== undefined && gpc !== undefined) {
            let newPL = JSON.parse(JSON.stringify(picklist));
            console.log(picklist)
            let updated = false;
            getDataModelPicklist(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                if(!data.error) {
                    let processed = {}
                    Object.keys(data.payload).map((pick) => {
                        processed[data.payload[pick][0].picklistId] = {}
                        data.payload[pick].map(elem => {
                            processed[elem.picklistId][elem.code] = elem;
                            processed[elem.picklistId][elem.code].conversion = '';
                            processed[elem.picklistId][elem.code].edited = false;
                        })
                    })
                    newPL.picklist = processed;
                }
                getPicklistRulesMapped(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                    if(!data.error) {
                        newPL.picklistConversions = data.payload
                    }
                    savePicklistSlice(newPL);
                    if(openOverlay) {
                        dispatch(setOpenOverlay(false))
                    }
                })
            })
        }
    }, [selectedDataModel])

    const changePicklist = (value, row, mapping, selectedPreview) => {
        if(mapping[row.fieldId] !== undefined && mapping[row.fieldId].value !== undefined) {
            let conversionsLocal = {
                rules: {
                }
            }
            conversionsLocal.rules = picklist.picklistConversions !== undefined ? picklist.picklistConversions[row.picklist] !== undefined ? JSON.parse(JSON.stringify(picklist.picklistConversions[row.picklist])) : {} : {};
            Object.keys(conversionsLocal.rules).map((key) => {
                if(conversionsLocal.rules[key] === value) {
                    delete conversionsLocal.rules[key];
                }
            })
            conversionsLocal.rules[showRaw(row, mapping, selectedPreview)] = value
            
            postPicklistRules(selectedDataModel.type, selectedDataModel.version, gpc, row.picklist, conversionsLocal).then((data) => {
                if(!data.error) {
                    console.log(picklist)
                    if(picklist.picklistConversions !== undefined) {
                        let copyOfObject = JSON.parse(JSON.stringify(picklist.picklistConversions));
                        Object.keys(picklist.picklistConversions[row.picklist]).map((key) => {
                            if(picklist.picklistConversions[row.picklist][key] === value) {
                                delete copyOfObject[row.picklist][key];
                            }
                        })
                        copyOfObject[row.picklist][showRaw(row, mapping, selectedPreview)] = value;
                        savePicklistConversions(copyOfObject) 
                        showUpdateMessage();
                    }else {
                        showUpdateMessageErr();
                    }
                }else {
                    showUpdateMessageErr();
                }      
            }).catch((error) => {
                showUpdateMessageServerErr();
            })
        }
    }

    const getComponentPicklist = (row, mapping, selectedPreview) => {
        let component = ''
        if(picklist !== undefined && picklist !== null) {
            if(row.multilingual !== true && row.multivalue !== true && row.picklist !== undefined && row.picklist !== null && picklist.picklist[row.picklist] !== undefined) {
                // console.log(row)
                // console.log(mapping);
                // console.log(showRaw(row, mapping, selectedPreview));
                // console.log(picklistConversions)
                component = <Grid container direction={"row"} columnSpacing={1} alignItems="center" alignContent={"center"}>
                                <Grid item xs={10}>
                                    {mapping[row.fieldId] !== undefined && mapping[row.fieldId].value !== undefined &&  showRaw(row, mapping) !== 'Not found' ? 
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel id="demo-simple-select-label">Select value</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={picklist.picklistConversions !== undefined && picklist.picklistConversions[row.picklist] !== undefined && picklist.picklistConversions[row.picklist][showRaw(row, mapping, selectedPreview)] !== undefined ? picklist.picklistConversions[row.picklist][showRaw(row, mapping, selectedPreview)] : ''}
                                                label="Select value"
                                                onChange={(event) => changePicklist(event.target.value, row, mapping, selectedPreview)}
                                                displayEmpty
                                                renderValue={(value) => {
                                                    if (value === '') {
                                                        return <em style={{color: "black"}}>Select Value</em>;
                                                    }
                                        
                                                    return value;
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {Object.keys(picklist.picklist[row.picklist]).map((key) => (
                                                    <MenuItem value={picklist.picklist[row.picklist][key].code}>{picklist.picklist[row.picklist][key].code}</MenuItem>
                                                )) }
                                            </Select>
                                        </FormControl>
                                    
                                    : 
                                        'Value need to convert'
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <GridActionsCellItem
                                        icon={<FormatListBulletedIcon />}
                                        label="Picklist"
                                        onClick={() => {dispatch(setOpenOverlay(true));saveRowPicklist(row)}}
                                    />
                                </Grid>
                            </Grid>
            }
        }
        return component;
    }

    return {
        getComponentPicklist,
        picklist,
        savePicklist,
        saveRowPicklist,
        savePicklistConversions,

    };
}