import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Fab, Box,  Slide, Backdrop, CircularProgress } from '@mui/material';
import useToken from '../App/useToken';
import Header from "../Header/Header";
import Title from "../Header/Title";
import { useNavigate  } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Bootstrap.css";
import menuOptions from "../Menu/menuOptions.js";
import toolsDataModelApi from "../../tools/toolsDataModelApi";
import ToolsNotifications from "../../tools/ToolsNotifications";
import ToolsDialog from "../../tools/ToolsDialog";
import { setRefreshDataModels } from '../../features/refreshDataModelsSlice';
import { useSelector } from 'react-redux';
import { setDataModel } from "../../features/dataModelSlice";
import { setSelectedDataModel } from "../../features/selectedDataModelSlice";
import { setGpc } from "../../features/gpcSlice";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import useUser from "../App/useUser";
import { setJoyrideState } from "../../features/joyrideSlice";
import {setUserAbility} from "../../features/userAbilitySlice";
import defineAbilityFor from "../../permission/defineAbility";
import { AbilityContext } from '../../permission/Can';
import ability from '../../permission/defineAbility';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import UpdateNotification from "../UpdateNotification/UpdateNotification";

export default function Bootstrap({children}) {
    const upd = useSelector((state) => state.openUpdateNotification.value);
    const jrState = useSelector((state) => state.joyrideState.value);
    const refreshDM = useSelector((state) => state.refreshDataModels.value);
    const openAlert = useSelector((state) => state.openAlert.value);
    const openDialog = useSelector((state) => state.openDialog.value);
    const openOverlay = useSelector((state) => state.openOverlay.value);
    const dispatch = useDispatch();
    const selectedIndex = useSelector((state) => state.selectedIndex.value);
    const { token, setToken, tokenValid } = useToken();
    const {close: closeNotification} = ToolsNotifications();
    const {close: closeDialog} = ToolsDialog();
    const navigate = useNavigate();
    const { getDataModels } = toolsDataModelApi();
    const { user } = useUser();

    const ref = useRef(null);
    useEffect(()=>{
        if(!token) {
            navigate('/login')
        }
    })

    useEffect(() => {
        console.log(upd)
    }, [upd])

    useEffect(() => {
        if(user !== undefined) {
            dispatch(setUserAbility(defineAbilityFor(user)));
        }
    }, [user])

    useEffect(() => {
        getDataModels().then((data) => {
            if(!data.error) {
                if(data.payload !== undefined && Array.isArray(data.payload)){
                    dispatch(setDataModel(data.payload))
                    dispatch(setSelectedDataModel(data.payload[0]))
                    if(data.payload[0].gpcs.length > 0) {
                        dispatch(setGpc(data.payload[0].gpcs[0].brick))
                    } else {
                        dispatch(setGpc(null))
                    }
                }
            }
        })
    },[])

    useEffect(() => {
        if(refreshDM) {
            getDataModels().then((data) => {
                if(!data.error) {
                    if(data.payload !== undefined && Array.isArray(data.payload)){
                        dispatch(setDataModel(data.payload))
                        dispatch(setSelectedDataModel(data.payload[0]))
                        dispatch(setGpc(data.payload[0].gpcs[0].brick))
                    }
                }
            })
            dispatch(setRefreshDataModels(false))
        }
    },[refreshDM])

    const handleAlertClose = () => {
        closeNotification();
    }

    const handleDialogClose = () => {
        closeDialog();
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#1eb1e0",
                contrastText: "#FAFAFA"
            },
            secondary: {
                main: "#FAFAFA",
                contrastText: "#1eb1e0"
            },
            orange:{
                main: "#E16C43",
            },
            red:{
                main: "red",
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                row:{         
                    "&:hover": {
                        backgroundColor: "blue !important"
                    }
                }
            }
        },
        typography: {
            addTitles: {
                fontFamily: 'Figtree',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '22px',
                color: '#4A4F53',
            },
            helperType: {
                fontFamily: "Figtree",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "20px",
                color: "#000000",
            },
            helperDB:{
                fontFamily: "Figtree",
                fontStyle: "normal",
                fontWeight: "200",
                fontSize: "15px",
                color: "#666E71",                
            },
            helperTitle: {
                fontFamily: "Figtree",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "20px",
                color: "#3284C6",
            },
            helperSubtitle: {
                fontFamily: "Figtree",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                color: "#000000",
            },
            helperText: {
                fontFamily: "Figtree",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "12px",
                color: "#000000",
            },
            important: {
              color: 'white',
              backgroundColor: '#1EB1E0',
              padding: '5px',
              borderRadius: '3px',
              fontWeight: '700'
            },
            productType: {
                color: 'white',
                backgroundColor: '#1EB1E0',
                paddingTop: '2px',
                paddingBottom: '2px',
                paddingLeft: '25px',
                paddingRight: '25px',
                borderRadius: '3px',
                fontWeight: '700'
            },
            productTitle: {
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#3078B0',
                textTransform: 'uppercase',
            },
            productSubtitle: {
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '22px',
                color: '#B6B6B6',
            },
            PDTitle:{
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "34px",
                color: "#DF5E3F",
            },
            PDLabel:{
                fontWeight: "700",
                color: "#1eb1e0",
                textTransform: 'uppercase',
            },
            PDValue:{
                color: "#4D4D4D",
            },
            selected: {
                color: '#1EB1E0',
                fontWeight: '700',
            },
            tableHeaderLight: {
                color: '#1EB1E0',
                fontWeight: '700',
                fontFamily: 'Figtree',
            },
            subtitle1: {fontWeight: "400", fontSize:"18px", lineHeight:"22px" },
            subtitle2: {fontWeight: "700", fontSize:"14px", lineHeight:"17px" },
            circular: {
                fontWeight: '700',
                fontSize: '22px',
                lineHeight: '27px',
                color: '#4D4D4D',
            },
            blue: {color:"#1eb1e0"},
            orange: {
                color: "#E16C43",
                fontWeight: '700',
            },
            qcProductDetailTitle: {
                backgroundColor: '#DEE9F2',
                padding: '14px 16px', 
                fontSize: '15px',
                fontFamily: 'Proxima Nova',
                fontWeight: 'bold'
            },
            qcProductDetailHierarchyTitle: {
                padding: '14px 16px',
                fontSize: '15px',
                fontFamily: 'Proxima Nova',
                backgroundColor: '#DEF4FB',
                color: '#203040',
                margin: '0'
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                "@global": {
                    "&::WebkitScrollbarThumb": {
                        width: "11px",
                        background: "#3284C6",
                        borderRadius: "40px"
                    },
                    "&::WebkitScrollbarTrack": {
                        background: "white"
                    }
                }
            }
        },
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        "&":{
                            color: "#B6B6B6",
                            backgroundColor: '#E4E4E4'
                        },
                        "&.Mui-selected": {
                            color: "#FAFAFA",
                            backgroundColor: '#1EB1E0'
                        },
                        "&.Mui-selected:hover": {
                            color: "#FAFAFA",
                            backgroundColor: '#1EB1E0'
                        },
                        "&:hover": {
                            color: '#FAFAFA',
                            backgroundColor: '#1EB1E0'
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: "#1EB1E0"
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                  select: {
                    backgroundColor: "#FAFAFA",
                    color: "#1EB1E0"
                  }
                }
            },
            MuiButton:{
                variants: [
                    {
                        props: { variant: 'main' },
                        style: {
                            "&": {
                                color: "#FAFAFA",
                                backgroundColor: '#1EB1E0'
                            },
                            "&:hover": {
                                color: "#FAFAFA",
                                backgroundColor: '#1EB1E0'
                            }
                        },
                    },
                    {
                        props: { variant: 'onneBlue' },
                        style: {
                            "&": {
                                color: "#3284C6",
                                backgroundColor: '#FAFAFA',
                                border: "2px solid #1EB1E0",
                                textTransform: "capitalize",
                            },
                            "&:hover": {
                                color: "#FAFAFA",
                                backgroundColor: '#1EB1E0',
                                border: "2px solid #1EB1E0",
                                textTransform: "capitalize",
                            }
                        },
                    },
                    {
                        props: { variant: 'onneGrey' },
                        style: {
                            "&": {
                                color: "#666E71",
                                backgroundColor: '#FAFAFA',
                                border: "2px solid #666E71",
                                textTransform: "capitalize",
                            },
                            "&:hover": {
                                color: "#FAFAFA",
                                backgroundColor: '#3284C6',
                                border: "2px solid #1EB1E0",
                                textTransform: "capitalize",
                            }
                        },
                    },
                    {
                        props: { variant: 'orange' },
                        style: {
                            "&":{
                                color: "#FAFAFA",
                                backgroundColor: '#E16C43',
                                border: "2px solid",
                                textTransform: "none",
                            },
                            "&:hover": {
                                color: "#E16C43",
                                backgroundColor: '#FAFAFA',
                                border: "2px solid #E16C43",
                                textTransform: "none",
                            }
                        },
                    },
                    {
                        props: { variant: 'orange-secondary' },
                        style: {
                            "&":{
                                color: "#E16C43",
                                backgroundColor: '#FAFAFA',
                                border: "2px solid",
                                textTransform: "none",
                            },
                            "&:hover": {
                                color: "#FAFAFA",
                                backgroundColor: '#E16C43',
                                border: "2px solid #E16C43",
                                textTransform: "none",
                            }
                        },
                    },
                ],
            },
            MuiTextField: {
                defaultProps: {
                  variant: "outlined",
                },
                styleOverrides: {
                    root: {                
                        "& label": {
                            // set some styles for the label if need it
                            color: "#1eb1e0"
                        },
                        "& input": {
                            // set some styles for the label if need it
                            color: "#1eb1e0"
                        },
                        "& legend": {
                            // set some styles for the legend if need it
                        },
                    }
                }
            }, 
            MuiInputBase: {
                defaultProps: {
                    variant: "outlined",
                },
                styleOverrides: {
                    root: {                
                        "& label": {
                            // set some styles for the label if need it
                            color: "#1eb1e0"
                        },
                        "& input": {
                            // set some styles for the label if need it
                            color: "#1eb1e0"
                        },
                        "& legend": {
                            // set some styles for the legend if need it
                        },
                    }
                }
            },
        }
    });

    const handleJoyrideStart = () => {
        dispatch(setJoyrideState({run: true, steps: jrState.steps}));
    };
    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type, action } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    
        if (finishedStatuses.includes(status) || action === 'close') {
            dispatch(setJoyrideState({run: false, steps: jrState.steps}));
        }
    };

    const descriptionElementRef = React.createRef();
    let Component = children.type;

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return  <div className="main" style={{backgroundColor: "#FFF"}}>
                <AbilityContext.Provider value={ability}>
                    <ThemeProvider theme={theme}>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{height: '100%'}}
                        >
                            <Joyride
                                callback={handleJoyrideCallback}
                                continuous
                                disableScrolling 
                                scrollToFirstStep
                                run={jrState.run}
                                steps={jrState.steps}
                                styles={{
                                    transition: "all .5s",
                                    options: {
                                        primaryColor: '#3284C6',
                                        zIndex: 10000,
                                    },
                                }}
                            />
                            <Grid item xs={0} sx={{ display: { xs: 'none', md: 'block' } }} md={2} height={"100%"}>
                                <Header />
                            </Grid>
                            <Grid height={'100%'} direction="row" item xs={12} md={10} container alignItems="left">
                                <Title name={menuOptions.menuItems[selectedIndex] ? menuOptions.menuItems[selectedIndex].title : selectedIndex}/>
                                <Component {...children.params } />
                            </Grid>
                            <Fab 
                                color="secondary" 
                                aria-label="add" 
                                onClick={handleJoyrideStart} 
                                sx={{position: 'absolute', bottom: 16, left: 16, visibility: jrState.steps.length > 0 ? 'visible' : 'hidden'}}
                            >
                                <QuestionMarkIcon />
                            </Fab>
                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
                                open={upd.open}
                                key={"topright"}
                                TransitionComponent={(props) => {return <Slide {...props} direction="up" />}}
                            >
                                <Box width="400px" backgroundColor="#E5F6FD" sx={{borderRadius: '10px', border: '2px solid #1EB1E0'}}>
                                    <UpdateNotification />  
                                </Box>                        
                            </Snackbar>
                            <Snackbar open={openAlert.open} autoHideDuration={6000} onClose={handleAlertClose}>
                                <Alert onClose={handleAlertClose} severity={openAlert.severity} sx={{ width: '100%' }}>
                                    {openAlert.message}
                                </Alert>
                            </Snackbar>
                            <Dialog
                                open={openDialog.open}
                                onClose={handleDialogClose}
                                scroll={openDialog.scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                maxWidth={'lg'}
                            >
                                <DialogTitle id="scroll-dialog-title">{openDialog.title}</DialogTitle>
                                <DialogContent dividers={openDialog.scroll === 'paper'}>
                                <DialogContentText
                                    id="scroll-dialog-description"
                                    ref={descriptionElementRef}
                                    tabIndex={-1}
                                >
                                    <Typography
                                    variant="body1"
                                    style={{whiteSpace: 'pre-wrap'}}
                                    >
                                    {openDialog.content}
                                    </Typography>
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    {openDialog.actions.map(elem => (
                                        elem
                                    ))}
                                </DialogActions>
                            </Dialog>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => 99999 }}
                                open={openOverlay}
                            >
                                <CircularProgress />
                            </Backdrop>
                        </Grid>
                    </ThemeProvider>
                </AbilityContext.Provider>
            </div>;
}